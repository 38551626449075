<template>
  <div :class="$style.FooterMonitoringLinks">
    <NuxtLink
      v-for="monitoring in monitorings"
      :key="monitoring.title"
      :to="monitoring.link"
      target="_blank"
    >
      <img
        :src="monitoring.image"
        :alt="monitoring.title"
        :title="monitoring.title"
        :class="$style.image"
      />
    </NuxtLink>
  </div>
</template>

<script setup lang="ts">
const monitorings = useMonitorings();
</script>

<style lang="scss" module>
.FooterMonitoringLinks {
  gap: 2rem;
  flex-wrap: wrap;
  max-width: 35rem;

  a {
    display: flex;
    border-radius: 5px;
    overflow: hidden;
  }
}

.image {
  height: 24px;
  filter: grayscale(1);
}
</style>
