<template>
  <ul :class="$style.FooterSocial">
    <li>
      <NuxtLink to="mailto:support@kotleta.gg" :class="$style.link">
        <SvgoIconMail :class="$style.icon" />
        <span>support@kotleta.gg</span>
      </NuxtLink>
    </li>
    <li>
      <NuxtLink to="tg://resolve?domain=Kotletanews" :class="$style.link">
        <SvgoIconTg :class="$style.icon" />
        <span>@Kotletanews</span>
      </NuxtLink>
    </li>
  </ul>
</template>

<script setup lang="ts"></script>

<style lang="scss" module>
.FooterSocial {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.link {
  display: flex;
  align-items: center;
  gap: 1rem;
  font-family: $font-family-secondary;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 5rem;
  color: $base-500;
  letter-spacing: -0.32px;

  @include respond-to(sm) {
    font-size: 14px;
  }

  @include respond-to(xs) {
    font-size: 10px;
    height: 100%;
  }
}

.icon {
  width: auto;
  height: 4rem;
  color: black;

  @include respond-to(sm) {
    height: 32px;
  }
}
</style>
