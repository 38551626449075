<template>
  <div :class="$style.Exchange">
    <img
      src="/images/background/lines.svg"
      alt="lines"
      draggable="false"
      :class="$style.background"
    />
    <div :class="$style.wrapper" class="container">
      <h1 class="h2 center" :class="$style.title">
        Безопасный обмен криптовалют
      </h1>
      <div :class="$style.wrap">
        <client-only>
          <ExchangeOperatingMode
            :class="$style.mode"
            :is-weekend="isWeekend"
            :is-new-year="isNewYear"
            is-desktop
          ></ExchangeOperatingMode>
        </client-only>
        <div :class="$style.inner">
          <PagesHomeExchangeContainer :step="step">
            <template #content>
              <PagesHomeExchangeBlock
                :step="step"
                :class="$style.content"
                :is-weekend="isWeekend"
                :is-new-year="isNewYear"
                :is-calculator-blocked="isCalculatorBlocked"
                :is-calculator-blocked-holidays="showNotificationHolidays"
                :is-button-blocked="isTechModeOn"
              />
            </template>
          </PagesHomeExchangeContainer>
          <transition name="fade">
            <PagesHomeExchangeTechMode
              v-if="isTechModeOn"
              :time="time"
              :class="$style.techmode"
            />
          </transition>
          <!--          <transition name="fade">-->
          <!--            <PagesHomeExchangeOperatingModeInfo-->
          <!--              v-if="-->
          <!--                (!isTechModeOn && isCalculatorBlocked) ||-->
          <!--                (!isTechModeOn && showNotificationHolidays)-->
          <!--              "-->
          <!--              :class="$style.info"-->
          <!--              :is-weekend="isWeekend"-->
          <!--              :is-holidays="showNotificationHolidays"-->
          <!--              :holidays-txt="holidaysTxt"-->
          <!--              @unblock-calculator="agree = true"-->
          <!--            />-->
          <!--          </transition>-->
        </div>
        <client-only>
          <CommonContestBlock :class="$style.contest" />
        </client-only>
        <PagesHomeExchangePairs v-if="false" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import moment from "moment-timezone";
import { useTech } from "~/composables/useTech";
import ExchangeOperatingMode from "~/components/pages/home/exchange/ExchangeOperatingMode.vue";

const step = ref(1);

const { on: isTechModeOn, time } = useTech();

const agree = ref(false);

const now = useNow({
  interval: 10000,
});

const moscowTime = computed(() => moment(now.value).tz("Europe/Moscow"));

const moscowHour = computed(() => moscowTime.value.hour());
const moscowDayOfWeek = computed(() => moscowTime.value.day());
const moscowDate = computed(() => moscowTime.value.date());
const moscowMonth = computed(() => moscowTime.value.month());

const isWeekend = computed(() => {
  const day = moscowDayOfWeek.value;
  return day === 0 || day === 6;
});

const isNewYear = computed(() => {
  const month = moscowMonth.value;
  const date = moscowDate.value;
  if (month === 11 && date === 31) {
    return "Сегодня тех. поддержка с 12:00 до 20:00";
  } else if (month === 0 && date === 1) {
    return "Сегодня тех. поддержка не работает";
  }
});

const showNotificationHolidays = ref(false);
const holidaysTxt = ref("");

const notificationPeriods = [
  {
    start: moment.tz("2024-12-31 00:00:00", "Europe/Moscow").toDate(),
    end: moment.tz("2024-12-31 12:00:00", "Europe/Moscow").toDate(),
    txt: "31 декабря поддержка доступна с 12:00 до 20:00 по МСК.",
  },
  {
    start: moment.tz("2024-12-31 20:00:00", "Europe/Moscow").toDate(),
    end: moment.tz("2024-12-31 23:59:59", "Europe/Moscow").toDate(),
    txt: "31 декабря поддержка доступна с 12:00 до 20:00 по МСК.",
  },
  {
    start: moment.tz("2025-01-01 00:00:00", "Europe/Moscow").toDate(),
    end: moment.tz("2025-01-01 23:59:59", "Europe/Moscow").toDate(),
    txt: "1 января поддержка не работает. с 2 января поддержка работает в обычном режиме с 08:00 до 24:00 по мск.",
  },
];

const shouldShowNotification = () => {
  const now = moment.tz(moment().format(), "Europe/Moscow");
  for (const period of notificationPeriods) {
    if (now.toDate() >= period.start && now.toDate() <= period.end) {
      holidaysTxt.value = period.txt;
      return true;
    }
  }
  return false;
};

onMounted(() => {
  showNotificationHolidays.value = shouldShowNotification(); // Изначальное состояние

  const checkInterval = setInterval(() => {
    if (agree.value) {
      return false;
    }
    showNotificationHolidays.value = shouldShowNotification();
  }, 1000);

  return () => clearInterval(checkInterval); // Остановка интервала при размонтировании
});

const isCalculatorBlocked = computed(() => {
  const hour = moscowHour.value;
  const weekend = isWeekend.value;

  if (agree.value) {
    showNotificationHolidays.value = false;
    return false;
  }

  if (weekend) {
    return !(hour >= 8 && hour < 24);
  } else {
    return !(hour >= 8 && hour < 24);
  }
});
</script>

<style lang="scss" module>
.Exchange {
  position: relative;
  width: 100%;
  min-height: 50rem;
  margin-top: -17rem;
  padding: 17rem 0 13rem;
  background: $body-color;

  @include respond-to(sm) {
    margin-top: -110px;
    padding: 110px 0 130px;
  }

  &::before {
    content: "";
    position: absolute;
    bottom: 1px;
    width: 100%;
    min-height: calc(50rem - 1px);
    height: 100%;
    background: $bg-color;
  }
}

.Exchange,
.Exchange::before {
  clip-path: polygon(
    0% 94%,
    0% 0%,
    100% 0%,
    100% 100%,
    48% 100%,
    40% 94%,
    24% 94%,
    22% 97%,
    7% 97%,
    5% 94%
  );

  @include respond-to(sm) {
    clip-path: polygon(
      0% 0%,
      5% 0%,
      7% 4%,
      22% 4%,
      24% 0%,
      40% 0%,
      48% 4%,
      100% 4%,
      100% 100%,
      0% 100%
    );
  }

  @include respond-to(xs) {
    clip-path: polygon(
      0% 0%,
      5% 0%,
      7% 2%,
      22% 2%,
      24% 0%,
      40% 0%,
      48% 2%,
      100% 2%,
      100% 100%,
      0% 100%
    );
  }
}

.background {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.02;
  filter: $invert;
}

.wrapper {
  position: relative;
  z-index: 1;
}

.title {
  margin-bottom: 4rem;
}

.wrap {
  position: relative;
  max-width: 98rem;
  margin: 0 auto;

  @include respond-to(md) {
    max-width: unset;
  }
}

.techmode {
  position: absolute;
  inset: 0;
}

.mode {
  position: absolute;
  left: -20rem;

  @include respond-to(md) {
    display: none;
  }
}

.contest {
  position: absolute;
  right: -22rem;
  max-width: 21rem;
  max-height: 20.7rem;
  top: 0;

  @include respond-to(md) {
    display: none;
  }

  @include respond-to(xs) {
    position: relative;
    right: unset;
    display: block;
    max-width: unset;
    min-height: 20.5rem;
    margin-bottom: 2rem;
  }
}

.inner {
  position: relative;
}

.content {
  padding: 3rem 2rem 6.4rem;

  @include respond-to(xs) {
    padding: 30px 32px 60px;
  }
}

.info {
  position: absolute;
  inset: 0;
}
</style>
