<template>
  <nav :class="[$style.MainMenu, { [$style._burger]: isBurger }]">
    <ul>
      <li
        v-for="{ title, link, submenu } in menuList"
        :key="title"
        ref="itemRefs"
        :class="{ [$style.active]: active?.title === title }"
      >
        <button
          v-if="submenu"
          :class="{ [$style.active]: show }"
          type="button"
          @click="onShowClick"
        >
          {{ title }}
          <SvgoIconArrow :class="[$style.icon, { [$style._active]: show }]" />
        </button>
        <NuxtLink v-else :to="link" :class="$style.link">
          <SvgoIconShops v-if="link === '/shops'" :class="$style.shops" />
          {{ title }}
        </NuxtLink>
        <div
          v-if="submenu"
          :class="[$style.submenu, { [$style._visible]: show }]"
        >
          <ul>
            <li v-for="child in submenu" :key="child.title">
              <NuxtLink :to="child.link" @click="show = false">
                {{ child.title }}
              </NuxtLink>
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </nav>
</template>

<script setup lang="ts">
const props = defineProps({
  isBurger: {
    type: Boolean,
    default: false,
  },
  isHeader: {
    type: Boolean,
    default: false,
  },
  menuList: {
    type: Array,
    default: () => [],
  },
});

const show = ref(false);

const itemRefs = ref([]);

const picker = computed(() => {
  return itemRefs.value[2];
});

onClickOutside(picker, () => {
  show.value = false;
});

const onShowClick = () => {
  show.value = !show.value;
};

const route = useRoute();
const active = computed(() => {
  return props.menuList.find((item: any) => item.link === route.path);
});
</script>

<style lang="scss" module>
.MainMenu {
  display: block;
  height: 100%;

  @include respond-to(sm) {
    display: none;
  }

  &._burger {
    display: none;

    @include respond-to(sm) {
      display: block;
    }
  }

  ul {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    gap: 3rem;

    @include respond-to(md) {
      gap: 20px;
    }
  }

  li {
    position: relative;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: normal;
    text-align: center;
    display: flex;
    height: 100%;
    white-space: nowrap;

    @include respond-to(sm) {
      height: auto;
    }
  }
}

._burger ul {
  @include respond-to(sm) {
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
  }

  li {
    @include respond-to(sm) {
      font-family: $font-family-secondary;
      font-size: 20px;
      letter-spacing: -0.48px;
    }

    @include respond-to(xs) {
      font-size: 14px;
      letter-spacing: -0.36px;
    }
  }

  button {
    @include respond-to(sm) {
      display: none;
    }
  }
}

.submenu {
  position: absolute;
  left: 0;
  bottom: -1rem;
  visibility: hidden;
  opacity: 0;
  padding: 3rem;
  background: $bg-color;
  border: 2px solid $body-color;
  transform: translateY(100%);
  transition: opacity 0.3s ease-in-out;

  @include respond-to(sm) {
    position: relative;
    left: unset;
    bottom: unset;
    opacity: unset;
    border: unset;
    visibility: unset;
    transform: unset;
    padding: unset;
  }

  ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;

    @include respond-to(sm) {
      gap: 30px;
    }
  }

  &._visible {
    opacity: 1;
    visibility: visible;
  }
}

.active {
  color: $brand-green;

  &:after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    border-bottom: 3px solid $brand-green;
  }
}

.icon {
  height: 0.9rem;
  width: 0.9rem;
  margin-left: 0.5rem;
  transform: rotate(180deg);

  &._active {
    transform: rotate(0deg);
  }
}

.shops {
  width: 1.8rem;
  flex-shrink: 0;

  @include respond-to(sm) {
    width: 24px;
  }
}

.link {
  display: inline-flex;
  align-items: center;
  gap: 0.4rem;

  @include respond-to(sm) {
    gap: 10px;
  }
}
</style>
